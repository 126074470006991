.home-container {
  @apply bg-white h-full w-full relative mb-20 py-2;
}
.home-row {
  @apply flex flex-row w-full;
}

/* .slick-prev {
    z-index: 1;
  }
  .slick-next {
    z-index: 1;
  } */
