.innovation-main {
  @apply flex flex-col relative justify-center md:py-10 items-center w-full;
}
.existing-main {
  @apply grid lg:grid-cols-3 grid-cols-2 justify-center items-center gap-5;
}
.applied-main {
  @apply flex flex-col w-full space-y-3 justify-center;
}
.applied-content-main {
  @apply grid lg:grid-cols-3 grid-cols-2 justify-center items-center gap-5;
}
.existing-card-main {
  @apply flex flex-col relative justify-evenly duration-200 space-y-2 px-3  w-96  h-72 border border-gray-300 rounded-lg hover:shadow-lg hover:shadow-GTI-BLUE-default;
}

.innovation-button {
  @apply font-medium rounded-lg text-sm px-5 py-2.5 mx-1 mb-2  focus:outline-none;
}
.innovation-active {
  @apply underline text-GTI-BLUE-default;
}
.innovation-not-active {
  @apply bg-white text-GTI-BLUE-default border-2 border-slate-200;
}

.innovation-div {
  @apply flex w-full md:px-10;
}
.existing-item {
  @apply flex flex-col  justify-center items-center;
}
.existing-item-action {
  @apply flex flex-row justify-center items-center;
}
.existing-company-logo {
  @apply flex h-16 w-1/2  object-cover px-3 py-2;
}

.innovation-modal-main {
  @apply absolute z-10 top-10 flex flex-col w-2/5 h-fit items-center space-y-5 bg-white duration-200 ease-in-out border border-gray-300 rounded justify-center p-10;
}
.modal-input {
  @apply mb-6 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

.hover-parent:hover .image-container > img {
  transform: scale(1.2);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

.innovation-main {
  @apply flex flex-col md:px-20 px-10 w-full py-5 h-full space-y-5 items-center;
}
.innovation-banner {
  @apply flex w-full shadow shadow-slate-500 justify-items-start  lg:h-96  md:h-48 sm:h-24 rounded object-cover;
}
.innovation-parent-details {
  @apply flex flex-col w-full items-center;
}
.innovation-details {
  @apply flex flex-col   w-4/5 px-20 py-2 lg:-translate-y-20 -translate-y-10 bg-white  shadow-lg rounded;
}
.innovation-group {
  @apply px-10 py-3;
}
.comp-logo {
  @apply h-20 w-40 rounded object-cover;
}
