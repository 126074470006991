.button{
    @apply    font-medium rounded-lg text-sm px-5 py-2.5 mx-1 mb-2  focus:outline-none;
}
.active{
    @apply bg-GTI-BLUE-default text-white hover:bg-blue-800 ;
}
.not-active{
    @apply bg-white text-GTI-BLUE-default border-2 border-slate-200;
}

.opp-card-main{
    @apply flex flex-col duration-150 border justify-center items-center  shadow hover:shadow-lg  hover:shadow-GTI-BLUE-default space-y-1 p-3  rounded-lg;
}
.opp-card-img{
    @apply flex w-full p-5;
}
.opp-card-title{
    @apply flex justify-center mx-2 space-y-1;
}
.opp-card-button{
    @apply w-full flex flex-row justify-center items-center;
}
.opp-list-main{
    @apply grid w-full px-10 lg:grid-cols-3 grid-cols-2 mx-auto lg:gap-x-20 gap-x-10 gap-y-5;
}


.product-single-main{
    @apply flex flex-col lg:px-20 px-10 w-full py-5 h-full space-y-5 items-center ;
    }
    .product-single-banner{
    @apply flex w-full shadow shadow-slate-500 justify-items-start  lg:h-96  md:h-48 sm:h-24 rounded object-cover relative;
    }
    .product-single-parent-details{
        @apply flex flex-col w-full relative items-center  ;
    }
    .product-single-details{
    @apply flex flex-col absolute py-5 w-4/5 px-20 lg:-translate-y-20 -translate-y-10 bg-white  shadow-lg rounded  ;
    }
    .product-single-group{
    @apply px-10 py-5 w-full  ;
    }
    .product-single-title{
    @apply text-lg font-roboto font-semibold;
    }
    .product-single-data{
    @apply font-roboto text-GTI-BLUE-default;
    }