.button {
  @apply font-medium rounded-lg text-sm px-5 py-2.5 mx-1 mb-2  focus:outline-none;
}
.active {
  @apply bg-GTI-BLUE-default text-white hover:bg-blue-800;
}
.not-active {
  @apply bg-white text-GTI-BLUE-default border-2 border-slate-200;
}

.product-card-main {
  @apply flex flex-col justify-start sm:m-10 duration-150 border md:pb-2 shadow-lg hover:shadow-lg hover:shadow-GTI-BLUE-default space-y-1 rounded-xl cursor-pointer;
}

.product-list-main {
  @apply grid w-full md:px-10 mx-auto lg:gap-x-5 gap-x-10 gap-y-10 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1;
}

.product-card-img {
  @apply flex w-full duration-150;
}

.product-card-title {
  @apply flex text-left px-4 space-y-1;
}

.product-card-button {
  @apply w-full flex flex-row justify-center items-center;
}

.product-single-main {
  @apply flex flex-col lg:px-20 px-10 w-full py-5 h-full space-y-5 items-center;
}
.product-single-banner {
  @apply flex w-full shadow shadow-slate-500 justify-items-start  lg:h-96  md:h-48 sm:h-24 rounded object-cover relative;
}
.product-single-parent-details {
  @apply flex flex-col w-full relative items-center;
}
.product-single-details {
  @apply flex flex-col absolute py-5 w-4/5 px-20 lg:-translate-y-20 -translate-y-10 bg-white  shadow-lg rounded;
}
.product-single-group {
  @apply px-10 py-5 w-full;
}
.product-single-title {
  @apply text-lg font-roboto font-semibold;
}
.product-single-data {
  @apply font-roboto text-GTI-BLUE-default;
}

.product-modal-main {
  @apply z-10 top-10 flex flex-col w-3/5 h-fit items-center space-y-5 bg-white duration-200 ease-in-out border border-gray-300 rounded justify-center p-10;
}
/* absolute  */
