.item {
  @apply xl:mx-3 text-sm xl:text-xl duration-200 lg:py-3 xl:px-1 rounded-lg md:m-3 m-2;
}

.item2 {
  @apply flex flex-row items-center lg:mx-3  px-1  rounded-lg;
}

.signModalActive {
  @apply text-GTI-BLUE-default bg-white border;
}

.signModal {
  @apply bg-GTI-BLUE-default text-white;
}
