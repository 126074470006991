.button{
    @apply    font-medium rounded-lg text-sm px-5 py-2.5 mx-1 mb-2  focus:outline-none;
}
.active{
    @apply bg-GTI-BLUE-default text-white hover:bg-blue-800 ;
}
.not-active{
    @apply bg-white text-GTI-BLUE-default border-2 border-slate-200;
}
.profile-main{
    @apply lg:flex lg:flex-col w-full mx-auto min-h-screen rounded-lg border-2 shadow-lg overflow-auto;
    }
    .profile-main-left-2{
        @apply flex  h-2/5 flex-col border-b-slate-200 border-b-2;
    }
    .profile-main-right{
        @apply flex flex-col  w-full h-full overflow-auto ;
    }
    .profile-main-right-content{
        @apply lg:flex flex-row items-center justify-around font-roboto mx-auto  w-1/2 my-2 p-1 overflow-visible;
    }
    .profile-top-cover{
    @apply w-full flex h-full object-contain rounded-t-lg relative;
    }
    .profile-top-row-2{
        @apply flex flex-row h-2/5 items-center justify-between;
    }
    .profile-pic-2{
        @apply object-cover flex items-center bg-white justify-center h-28 w-28 rounded-full border-4 shadow-lg shadow-slate-300  border-white;
    }
    .profile-name{
        @apply lg:-translate-y-0 -translate-y-5 lg:p-12 p-6 lg:text-center lg:mb-5 font-bold lg:text-xl text-sm;
    }
    .profile-top-row-right{
        @apply inline-flex flex-row mb-5 -translate-y-5;
    }
    .profile-cancle-btn{
        @apply mx-3 rounded px-3 py-1 text-blue-700 ring-1 ring-blue-500 hover:text-white hover:bg-blue-700 hover:ring-1 hover:ring-blue-100 duration-200 hover:scale-90;
    }
    .profile-save-btn{
        @apply mx-3 rounded px-4 py-1 text-white bg-blue-700 ring-1 ring-blue-100 hover:bg-white hover:ring-blue-700 hover:text-blue-700 duration-200 hover:scale-90;
    }
    .profile-dropdown{
        @apply lg:hidden text-white bg-blue-800  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 m-1;
    }
    .profile-dropdown-content{
        @apply py-1 text-sm text-gray-700 dark:text-gray-200 lg:hidden;
    }
    .profile-dropdown-content-title{
        @apply block py-2 px-4 text-slate-500 focus:bg-slate-400 focus:text-white;
    }
    
    
    
    .profile-group
    {
        @apply flex flex-shrink  w-4/5 flex-col mx-10 p-3;
    }
    .profile-row-group
    {
        @apply w-full inline-flex flex-shrink flex-col lg:flex-row justify-between items-center p-2;
    }
    .profile-content-head
    {
        @apply text-slate-500 py-2 font-roboto text-sm font-semibold pl-10;
    }
    .profile-content-head-2
    {
        @apply text-slate-500 py-2 font-roboto text-sm font-semibold pl-3;
    }
    .profile-content-head-2-error
    {
        @apply text-red-500 py-2 font-roboto text-sm font-semibold pl-3;
    }
    .profile-content-input
    {
        @apply text-slate-800 font-roboto rounded-md ring-slate-400 border-0 ring-1 flex-shrink  focus:ring-blue-500;
    }
    .profile-main-right-content-title{
        @apply inline-flex w-full py-3  my-2 text-sm font-bold items-center justify-center rounded-lg duration-200;
    }
    .personal-input{
        @apply bg-gray-50 border border-gray-300  placeholder-slate-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
    }