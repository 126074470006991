.event-button {
  @apply w-full items-center justify-center font-medium rounded-lg inline-flex text-sm px-5 py-2.5 mx-1 mb-2  focus:outline-none;
}
.active {
  @apply bg-GTI-BLUE-default text-white hover:bg-blue-800;
}
.not-active {
  @apply bg-white text-GTI-BLUE-default border border-GTI-BLUE-default;
}

.event-main {
  @apply flex flex-col lg:px-20 px-2 w-full lg:py-5 h-full  space-y-5 items-center;
}

.event-video {
  @apply flex flex-col lg:px-20 px-2 w-full h-full items-center;
}

.event-banner {
  @apply flex w-full shadow shadow-slate-500 justify-items-start  lg:h-96  md:h-48 sm:h-24 rounded object-cover relative;
}

.event-parent-details {
  @apply flex flex-col w-full relative items-center;
}

.event-details {
  @apply flex flex-col justify-center items-center pb-10 lg:w-4/5 lg:px-20 lg:-translate-y-20 -translate-y-10 bg-white shadow-lg rounded;
}

.event-group {
  @apply md:px-10 px-2 lg:py-5 py-1 w-full;
}
