.w-3-5-12 {
  width: calc(3.5 / 12 * 100%);
}

.description {
  /* height: 3rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
