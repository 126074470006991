.container {
  @apply z-10 bg-slate-700 fixed flex justify-center items-center bg-opacity-80 max-w-full max-h-full h-full w-full transition-all duration-700;
}

.main {
  @apply bg-white justify-between md:inline-flex w-full md:w-3/6 rounded-lg md:mx-auto mx-4 bg-opacity-100;
}

.header {
  @apply inline-flex justify-between items-center py-2;
}

.header-content {
  @apply inline-flex;
}

.content-label {
  @apply w-full rounded-lg my-2 text-slate-500 text-sm;
}

.content-input {
  @apply w-full rounded-lg my-2 border-slate-300 focus:border-blue-700 ring-1 ring-blue-500;
}

.content-button {
  @apply w-2/3 inline-flex justify-center items-center rounded-lg bg-GTI-BLUE-default  text-white p-2;
}

.content-checkbox {
  @apply rounded items-center;
}

.group {
  @apply my-5 items-center;
}

.signup {
  @apply p-2 rounded font-bold mx-2 text-sm lg:text-lg duration-300;
}

.signin {
  @apply p-2 rounded font-bold text-GTI-BLUE-default text-sm lg:text-lg duration-300;
}

.content-block-signin {
  @apply inline-flex md:hidden w-full p-2 justify-center;
}

.content-block {
  @apply w-full p-2 inline-flex justify-center;
}

.content-extra {
  @apply text-center text-xs  lg:text-sm p-1 text-slate-700;
}
.content-extra-signup {
  @apply text-center text-xs lg:text-xl p-1 text-slate-700;
}
.selected {
  @apply border-b-4  border-blue-600 bg-opacity-0;
}

.side-left-signin {
  @apply hidden py-4 w-full md:w-1/2 items-center md:flex justify-center rounded-lg;
}
.side-right-signin {
  @apply lg:w-1/2 p-5 rounded-lg;
}

.side-left {
  @apply hidden py-4 w-full md:w-1/2 items-center md:flex justify-center rounded-lg;
}
.side-right {
  @apply lg:w-1/2 p-5 rounded-lg;
}
.part-t {
  @apply text-sm text-slate-700 text-center;
}
.part-b {
  @apply text-slate-700 font-bold text-center;
}
.cancel {
  @apply text-center font-bold px-4 mx-2 ring-2 ring-red-500 bg-red-500;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
